<!-- ----------------------------------------------------------------------- -->
<!--
name    : ADDRESS VALIDATION MAP

type    : component

used by : AppClientCreate
          AppClientJobSiteCreate
          AppClientServiceRequestCreate
          AppJobSiteEdit
          AppServiceRequestCreate


uses    : gmapsInit

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div id="mapWrapper" class="address-validation-map-component">
    <!-- address search input -->
    <v-text-field
      ref="origin"
      id="placeAutoComplete"
      :disabled="disabled"
      v-on:focus="geolocate"
      placeholder=""
      :value="inputValue"
      label="Enter Job Site Address and Select"
      :rules="rules" />

    <!-- map display -->
    <div id="mapDiv" class="mt-1" />
  </div>
</template>

<script>
  // services
  import Tenants from '@/services/Tenants.service.js';
  import gmapsInit from '@/js/gmaps';

  // mixins
  import addresses from '@/mixins/addresses';
  import Localization from '@/mixins/localization';

  export default {
    name: 'AddressValidationMap',
    mixins: [addresses, Localization],
    props: {
      disabled: Boolean,
      addressSearch: String,
      rules: Array,
    },
    data() {
      return {
        map: undefined,
        geocoder: undefined,
        autocomplete: undefined,
        markers: [],
        infoWindows: [],
        inputValue: undefined,
        tenantSettings: {},
        clientConfigurations: {},
      };
    },
    watch: {
      addressSearch: async function (value) {
        // verify map is loaded
        await this.geocoder;
        if (this.geocoder) {
          // if non-empty address string is passed in, update
          // field and map
          if (value) {
            this.inputValue = value;
            this.renderJobSitesMap(this.inputValue);

            // reset fields if empty string is passed in
            // (this is currently only done by AppClientServiceRequestCreate
            // by choosing to select a new jobsite after previously using an
            // existing one and passing that address to this component
          } else {
            // reset field to empty
            this.$refs['origin'].reset();
            this.renderJobSitesMap();
            // this.$refs.inputRef.reset()
          }
        }
      },
      disabled: function (value) {
        if (value == true) {
          this.renderJobSitesMap();
        }
      },
    },
    async mounted() {
      // get tenant settings
      // (in an authenticated situation we already have this info
      // via tenantProfile that gets loaded in $auth during the
      // begging of the user's session. This special case
      // is needed for the connected components that need this info
      // passed in for unsecured estimate proposal links)

      // load client config:
      this.clientConfigurations = this.$getConfig().client_configurations;

      this.tenantSettings = this.$auth.tenantProfile
        ? this.$auth.tenantProfile
        : await Tenants.getSettingsInfo(
            this.$auth.userProfile.tenant_uuid
            // unsecured route
          );

      try {
        // load google maps API
        const google = await gmapsInit(
          this.clientConfigurations.google.maps_api_key
        );

        // once API is initialized, continue
        if (google) {
          // instantiate the autocomplete for text field $ref
          // Note: the extra `.$refs.input` on the end is necessary for <v-text-field>,
          // if it was just an <input> field it would not need it
          const autocomplete = new google.maps.places.Autocomplete(
            this.$refs['origin'].$refs.input
          );

          // add input listener
          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place && place.formatted_address && place.address_components) {
              // update displayed input text value
              this.inputValue = place.formatted_address;
              // parse address into object and emit update
              this.updateAddress(place.address_components);
              // update map
              this.renderJobSitesMap(place.formatted_address);
            }
          });

          // load the map
          this.geocoder = new google.maps.Geocoder();
          if (this.tenantSettings && this.tenantSettings.country) {
            this.map = new google.maps.Map(document.getElementById('mapDiv'), {
              mapTypeId: 'hybrid',
              zoom: this.mapDSettingsByCountry[this.tenantSettings.country]
                .zoom,
              center:
                this.mapDSettingsByCountry[this.tenantSettings.country].center,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    methods: {
      parseFromAddress(components, type) {
        for (let i = 0; i < components.length; i++)
          for (let j = 0; j < components[i].types.length; j++)
            if (components[i].types[j] == type) return components[i].long_name;
        return '';
      },
      updateAddress(components) {
        const job_site_addr = {
          address:
            this.parseFromAddress(components, 'street_number') +
            ' ' +
            this.parseFromAddress(components, 'route'),
          city:
            this.parseFromAddress(components, 'locality') ||
            this.parseFromAddress(components, 'postal_town'),
          state: this.parseFromAddress(
            components,
            'administrative_area_level_1'
          ),
          zip_code: this.parseFromAddress(components, 'postal_code'),
          country: this.parseFromAddress(components, 'country'),
        };

        // emit updated jobsite to parent views
        this.$emit('jobSiteValidated', job_site_addr);
      },
      geolocate() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const geolocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              // eslint-disable-next-line no-undef
              const circle = new google.maps.Circle({
                center: geolocation,
                radius: position.coords.accuracy,
              });
              this.autocomplete.setBounds(circle.getBounds());
            }.bind(this)
          );
        }
      },
      renderJobSitesMap(address) {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
        this.markers = [];

        if (address && address != '') {
          this.geocoder.geocode(
            {
              address: address,
            },
            function (results) {
              if (results && results[0]) {
                this.map.setCenter(results[0].geometry.location);

                const contentString =
                  '<div class="content">' +
                  '<h2 class="firstHeading">Job Site</h2>' +
                  '<div id="bodyContent">' +
                  '<h4 style="margin:5px 0;">' +
                  results[0].formatted_address +
                  '</h4>' +
                  '</div>' +
                  '</div>';
                // eslint-disable-next-line no-undef
                const infowindow = new google.maps.InfoWindow({
                  content: contentString,
                });

                const markerOptions = {
                  map: this.map,
                  // eslint-disable-next-line no-undef
                  position: new google.maps.LatLng(
                    results[0].geometry.location.lat(),
                    results[0].geometry.location.lng()
                  ),
                  zIndex: 1,
                };

                // eslint-disable-next-line no-undef
                const marker = new google.maps.Marker(markerOptions);
                infowindow.open(this.map, marker);
                this.markers.push(marker);

                this.map.setZoom(17);
              }
            }.bind(this)
          );
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  #mapWrapper {
    position: relative;
    height: 45vh;
  }
  #mapDiv {
    position: absolute;
    left: 0;
    right: 0;
    top: 64px;
    bottom: 0;
  }
  .button {
    transition: background-color 0.5s ease;
    background-color: #004370;
    /* dark blue */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }
  .button:hover {
    background-color: #49b6ff;
    /* dark blue */
  }
  #legend {
    font-family: Arial, sans-serif;
    background: #fff;
    padding: 10px;
    margin: 10px;
    border: 3px solid #000;
  }
  #legend h3 {
    margin-top: 0;
  }
  .thumbnailRow {
    max-height: 250px;
    overflow-y: auto;
  }
  #fileInput {
    display: none !important;
  }
</style>
